import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import "react-calendar/dist/Calendar.css";
import { components } from "react-select";
import { Button, Pagination, Table } from "react-bootstrap";
import search_icon from "../assets/search_icon.svg";
import download_icon from "../assets/download_icon.svg";
import refresh_icon from "../assets/refresh_icon.svg";
import Calendar from "react-calendar";
import { default as ReactSelect } from "react-select";
import "./Main.scss";
import McDLogo from "./assets/McDLogo.png";
import axios from "axios";
import * as XLSX from "xlsx";
import { GDSRecords } from "../ENDE";
import Swal from "sweetalert2";

import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faPlus,
  faDownload,
  faSort,
  faEdit,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import SidebarContent from "./Sidebar";
import CryptoJS from "crypto-js";
import Papa from "papaparse"; // Import PapaParse library
// const apiEN = process.env.REACT_APP_GES1;
const apiDE = process.env.REACT_APP_GES3;

export default function Stores() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [shouldFetchData, setShouldFetchData] = useState(true);
  const [selectedSearchFilter, setSelectedSearchFilter] = useState(null);
  const [isDateConstVisible, setIsDateConstVisible] = useState(false);
  const [isSortFieldDBVisible, setisSortFieldDBVisible] = useState(false);
  const [SortFieldDB, setSortFieldDB] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [DateConst, setDateConst] = useState(new Date());
  const [totalCount, setTotalCount] = useState(0);
  const [selectedFile, setselectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [columns, setcolumns] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [IsUploading, setIsUploading] = useState(false);
  const Storekeys = [
    "STORE_ID",
    "STORE_NAME",
    "MANAGER_NAME",
    "LOCATION",
    "PIN_CODE",
  ];
  const Transactionkeys = [
    "Zone",
    "Store Name",
    "City",
    "POS",
    "Hardware Model",
    "Hardware ID",
    "Acquirer",
    "TID",
    "MID",
    "Batch No",
    "Payment Mode",
    "Customer Payment Mode ID",
    "Name",
    "Card Issuer",
    "Card Type",
    "Card Network",
    "Card Colour",
    "Transaction ID",
    "Invoice",
    "Approval Code",
    "Type",
    "Amount",
    "TIP Amount",
    "Currency",
    "Date",
    "Batch Status",
    "Txn Status",
    "Settlement Date",
    "Bill Invoice",
    "RRN",
    "EMI Txn",
    "EMI Month",
    "Contactless",
    "Contactless Mode",
    "Cloud Ref ID",
    "Card Pan Check for Sale Complete",
    "Route Preauth to Other Acquirer",
    "Billing Transaction Id",
  ];
  const handleFileChange = (e) => {
    try {
      console.log(e.target.files[0]);
      setselectedFile(e.target.files[0]);
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpload = async () => {
    setIsUploading(true);
    const file = selectedFile;
    var keys = UploadTransactions ? Transactionkeys : Storekeys;
    if (!file) {
      console.error("No file selected.");
      setIsUploading(false);
      return;
    }

    let allErrors = []; // Accumulate all errors here

    // Set initial progress
    let progress = 0;

    // Update progress for file reading
    const fileReadProgressRatio = 0.4; // 40% for file reading
    progress += fileReadProgressRatio * 100;
    setProgress(progress);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        var jsonData = results.data;
        if (UploadTransactions) {
          // alert("in");
          jsonData = jsonData.filter(
            (row) => row["Txn Status"].toLowerCase() !== "session expired"
          );
          console.log(jsonData[267]);
        }
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(jsonData),
          "secret_key"
        ).toString();

        try {
          // Simulate file reading delay (remove this in production)
          await new Promise((resolve) => setTimeout(resolve, 1000));

          // Check if all keys exist in the sheet
          const sheetKeys = Object.keys(jsonData[0]);
          const missingKeys = keys.filter((key) => !sheetKeys.includes(key));
          if (missingKeys.length > 0) {
            allErrors.push(
              `The following keys are missing in the uploaded file: ${missingKeys.join(
                ", "
              )}`
            );
          }

          // Check for null values in required columns
          var CompulsoryTransactionFileKeys = [
            "Billing Transaction Id",
            // "Txn Status",
            // "Bill Invoice",
          ];
          if (!UploadTransactions || "1234" === "123x4") {
            jsonData.filter((row, index) => {
              var nullKeys;
              if (UploadTransactions) {
                nullKeys = keys.filter(
                  (key) =>
                    (row[key] === null ||
                      row[key] === undefined ||
                      row[key] === "") &&
                    CompulsoryTransactionFileKeys.includes(key)
                );
              } else {
                nullKeys = keys.filter(
                  (key) =>
                    row[key] === null ||
                    row[key] === undefined ||
                    row[key] === ""
                );
              }

              if (nullKeys.length > 0) {
                allErrors.push(
                  `Null value(s) found in row ${
                    index + 2
                  }, column(s): ${nullKeys.join(", ")}`
                );
                return true;
              }
              return false;
            });

            // Combine other error checks here

            if (allErrors.length > 0) {
              throw new Error("Errors found in the uploaded file");
              //  NOT THROWING ERROR
            }
          }

          // File reading completed, now upload encrypted data
          const options = {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const uploadProgress = (loaded / total) * 0.6 * 100; // 60% for uploading
              setProgress(progress + uploadProgress);
            },
          };

          // Simulate uploading delay (remove this in production)
          await new Promise((resolve) => setTimeout(resolve, 1000));
          if (UploadTransactions) {
            const response = await axios.post(
              process.env.REACT_APP_API_LINK + "/rfapi/uploadTransactionsData",
              {
                data: encryptedData,
              },
              options
            );
            console.log(response.data);

            if (
              response.data.message ===
              "Data received, decrypted, and upserted successfully."
            ) {
              Swal.fire({
                title: "Success",
                text: "File Uploaded Successfully",
                icon: "success",
              });
              fetchData();
              toggleModal(true);
            }
          } else {
            axios
              .post(
                process.env.REACT_APP_API_LINK +
                  "/rfapi/uploadDataDuplicatesCheck",
                {
                  data: encryptedData,
                }
              )
              .then(async (response) => {
                console.log("Response:", response.data);
                if (response.data.message === "Can Proceed") {
                  const response = await axios.post(
                    process.env.REACT_APP_API_LINK + "/rfapi/uploadData",
                    {
                      data: encryptedData,
                    },
                    options
                  );
                  console.log(response.data);

                  if (
                    response.data.message ===
                    "Data received, decrypted, and upserted successfully."
                  ) {
                    Swal.fire({
                      title: "Success",
                      text: "File Uploaded Successfully",
                      icon: "success",
                    });
                    fetchData();
                    toggleModal(true);
                  }
                } else {
                  const duplicates = response.data.duplicates;
                  const duplicatescolumns = Object.keys(
                    response.data.duplicates[0]
                  );

                  // Generate HTML for the table
                  let tableHtml =
                    "<table><thead><tr><th>Duplicate Value</th></tr></thead><tbody>";
                  tableHtml += duplicates
                    .map(
                      (item, index) =>
                        "<tr key=" +
                        index +
                        ">" +
                        duplicatescolumns
                          .map(
                            (field, fieldindex) =>
                              "<td key=" +
                              fieldindex +
                              ' style="color: #06152B; font-weight: 400; font-size: 0.81rem;">' +
                              `<p style="width: inherit">` +
                              item[field] +
                              "</p>" +
                              "</td>"
                          )
                          .join("") +
                        "</tr>"
                    )
                    .join("");
                  tableHtml += "</tbody></table>";

                  // Display Swal modal with custom HTML
                  Swal.fire({
                    title: "Warning",
                    html: tableHtml,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Proceed with Duplicates",
                    cancelButtonText: "Cancel",
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      const response = await axios.post(
                        process.env.REACT_APP_API_LINK + "/rfapi/uploadData",
                        {
                          data: encryptedData,
                        },
                        options
                      );
                      console.log(response.data);

                      if (
                        response.data.message ===
                        "Data received, decrypted, and upserted successfully."
                      ) {
                        Swal.fire({
                          title: "Success",
                          text: "File Uploaded Successfully",
                          icon: "success",
                        });
                        fetchData();
                        toggleModal(true);
                      }
                    }
                  });
                }
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          }

          // Update progress to 100% when response is received
          setProgress(100);
        } catch (error) {
          console.error("Error uploading file:", error);
          Swal.fire({
            title: "Error",
            text: "Error Uploading the File",
            icon: "error",
          });
        } finally {
          setIsUploading(false);
          if (allErrors.length > 0) {
            const errorMessage = allErrors
              .map((err) => `<p>${err}</p>`)
              .join("");
            Swal.fire({
              title: "Error",
              html: `<p>
                          Error uploading file:</p>${errorMessage}`,
              icon: "error",
            });
          }
        }
      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
        setIsUploading(false);
        Swal.fire({
          title: "Error",
          text: "Error Parsing CSV File",
          icon: "error",
        });
      },
    });
  };

  const encryptFile = async (file) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = () => {
        const fileData = new Uint8Array(reader.result);
        const encryptedData = CryptoJS.AES.encrypt(
          fileData.toString(),
          "your-secret-key"
        );
        const blob = new Blob([encryptedData.toString()], {
          type: "application/octet-stream",
        });
        resolve(blob);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  const [UploadTransactions, setUploadTransactions] = useState(false);
  const toggleModal = (close) => {
    setModalOpen(!modalOpen);
    setProgress(0); // Reset progress when modal opens
    if (close === true) {
      setselectedFile(null);
      setModalOpen(false);
    }
  };
  const [sortOrder, setSortOrder] = useState(null);
  const [data, setdata] = useState(null);
  const [editMode, seteditMode] = useState(false);
  const [editedValues, setEditedValues] = useState(
    Array(data?.length).fill({})
  );
  const [EditRow, setEditRow] = useState([]);
  const toggleEditMode = () => {
    seteditMode(!editMode);
  };

  const handleInputChange = (e, field, index, id) => {
    const { value } = e.target;
    console.log(field);
    // Validation: Check if the field is either "pincode" or "store_id" and if the value is a number
    if (
      (field === "PIN_CODE" || field === "STORE_ID") &&
      isNaN(Number(value))
    ) {
      // If the value is not a number, you can handle the error here
      console.error(`Invalid input for ${field}: ${value}`);
      // Don't update the state if the input is invalid
      return;
    }
    console.log({
      UPDATE_ID: "id",
    });
    console.log(EditRow);

    setEditRow((prevState) => ({
      ...prevState,
      [field]: value,
      UPDATE_ID: id,
    }));
  };

  const handleEditSubmit = async (index) => {
    try {
      // Extract id from editedValues

      // Encrypt the edited data
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(EditRow),
        "secret_key"
      ).toString();
      console.log(JSON.stringify(EditRow));

      // Send the encrypted data to the backend
      const response = await axios.post(
        process.env.REACT_APP_API_LINK + `/rfapi/updateRow`,
        {
          data: encryptedData,
        }
      );

      console.log(response.data);

      if (response.data.message === "Store updated successfully") {
        Swal.fire({
          title: "Success",
          text: "Store updated successfully",
          icon: "success",
        });
        toggleEditMode(index);
        fetchData();
        toggleModal(true);
      }
    } catch (error) {
      console.error("Error submitting edited data:", error);
      Swal.fire({
        title: "Error",
        text: error.message || "Failed to update store",
        icon: "error",
      });
    }
  };

  const ccList = [
    { value: "STORE_ID", label: "Store ID" },
    { value: "STORE_NAME", label: "Store Name" },
    { value: "MANAGER_NAME", label: "Manager Name" },
    { value: "LOCATION", label: "Location" },
    { value: "PIN_CODE", label: "Pin Code" },
  ];

  const handlePageChange = (value) => {
    setPage(value);
  };
  const pageCount = Math.ceil(totalCount / pageSize);
  const onDateConstChange = (value) => {
    setDateConst(value);
    setIsDateConstVisible(!isDateConstVisible);
  };
  function getDateLabel(date) {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const inputDate = new Date(date);
    inputDate.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, and milliseconds

    if (inputDate.toDateString() === today.toDateString()) {
      return "Today";
    } else if (inputDate.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else {
      return inputDate.toISOString().substring(0, 10);
    }
  }
  const fetchData = async () => {
    try {
      setIsLoading(true);
      console.log({
        sortField: SortFieldDB,
        searchValue: searchValue,
        searchFilter: selectedSearchFilter,
      });
      await axios({
        url: process.env.REACT_APP_API_LINK + `/rfapi/getStoreData`,
        method: "POST",
        responseType: "json",
        // withCredentials: true,

        data: {
          sortField: SortFieldDB,
          searchValue: searchValue,
          searchFilter: selectedSearchFilter,
          page: page,
          sortOrder: !sortOrder ? "asc" : sortOrder,
        },
      }).then((response) => {
        if (response) {
          var datax = response.data.data;
          console.log("datax");
          console.log(datax);
          if (datax.length > 0) {
            // datax = datax.stores;
            console.log("in");

            console.log(datax);
            console.log(typeof datax);
            setdata(datax);
            setTotalCount(response.data.totalCount);
            setcolumns(Object.keys(datax[0]));
          }
        }
      });
    } catch (err) {
      console.log("error fetching ");
      alert("error fetching data");
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  const downloaddata = async () => {
    try {
      const response = await axios({
        url: process.env.REACT_APP_API_LINK + `/rfapi/getStoreData`,
        method: "POST",
        responseType: "json",
        // withCredentials: true,

        data: {
          sortField: SortFieldDB,
          searchValue: searchValue,
          searchFilter: selectedSearchFilter,
          page: page,
          download: true,
        },
        responseType: "blob", // Set the response type to blob to handle binary data
      });

      // Create a blob object from the response data
      const blob = new Blob([response.data], { type: "text/csv" });

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "data.csv";

      // Append the link to the body and click it to trigger the download
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Cleanup
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  // useEffect(() => {
  //         fetchData(SortFieldDB, searchValue, selectedSearchFilter,page,sortOrder);
  // }, [page]);
  useEffect(() => {
    if (
      SortFieldDB ||
      (searchValue && selectedSearchFilter) ||
      (!searchValue && !selectedSearchFilter)
    ) {
      fetchData();
    }
  }, [SortFieldDB, searchValue, selectedSearchFilter, sortOrder, page]);

  const handleSort = (field) => {
    console.log(field);
    console.log(SortFieldDB);
    console.log(sortOrder);
    if (field === SortFieldDB) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortFieldDB(field);
      setSortOrder("asc");
    }
  };
  const pageNumbers = [];
  for (let i = 1; i <= pageCount; i++) {
    pageNumbers.push(i);
  }

  const getPageItems = () => {
    const items = [];

    // Add Prev button
    items.push(
      <Pagination.Prev
        key="prev"
        onClick={() => {
          if (page > 1) {
            handlePageChange(page - 1);
          }
        }}
        disabled={page <= 1}
      />
    );

    // Add page numbers
    for (
      let i = Math.max(1, page - 4);
      i <= Math.min(pageCount, page + 5);
      i++
    ) {
      items.push(
        <Pagination.Item
          key={i}
          active={page === i}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    // Add Next button
    items.push(
      <Pagination.Next
        key="next"
        onClick={() => {
          if (page < pageCount) {
            handlePageChange(page + 1);
          }
        }}
        disabled={page >= pageCount}
      />
    );

    return items;
  };

  const NavV = () => {
    return (
      <p
        className="align-self-end"
        style={{
          padding: "2%",
          display: "flex",
          backgroundColor: "white",
          margin: 0,
          marginTop: "0rem",
          flexDirection: "row",
          alignItems: "center",
          borderBottom: "0.1px solid gray",
          // , marginBottom: 20
        }}
      >
        {"Home > "}
        <p
          className="align-self-end"
          style={{
            margin: 0,
            marginTop: "0rem",
            marginLeft: "0.51rem",
            fontWeight: "700",
          }}
        >
          {"Stores"}
        </p>
      </p>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        width: "100%",
        backgroundColor: "#f5f5f5",
      }}
    >
      <SidebarContent />
      <div
        style={{
          padding: "0%",
          margin: "0%",
          flex: 1,
        }}
      >
        <NavV />
        <div
          style={{
            padding: "1.52%",
            backgroundColor: "#f5f5f5",
          }}
        >
          <div
            style={{
              borderRadius: "20px",
              padding: "1.52%",
              backgroundColor: "white",
            }}
          >
            <div
              style={{
                display: "flex",
                margin: 0,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* <h1
                                style={{
                                    display: "inline-block",
                                    width: "27%",
                                    fontSize: "1rem",
                                    fontWeight: "700",
                                }}
                            >
                                Stores
                            </h1> */}
              <div
                className="d-flex flex-wrap"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "stretch",
                }}
              >
                <div className="customx-input-group" style={{ maxWidth: 800 }}>
                  <img
                    src={search_icon}
                    alt=""
                    width={40}
                    style={{
                      padding: 10,
                    }}
                    id="basic-addon1"
                  />
                  <input
                    type="text"
                    className="customx-form-control"
                    style={{
                      minWidth: 400,
                    }}
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                    placeholder="Search here"
                  />
                  <ReactSelect
                    className="customx-form-control border-light"
                    styles={{
                      control: (baseStyle, state) => ({
                        ...baseStyle,
                        borderColor: "white",
                        "&:hover": {
                          borderColor: "white",
                        },
                        boxShadow: 0,
                      }),
                    }}
                    placeholder={"Search Field"}
                    options={ccList}
                    hideSelectedOptions={false}
                    isClearable={true}
                    onChange={setSelectedSearchFilter}
                    value={selectedSearchFilter}
                  />
                </div>

                {modalOpen && (
                  <div className="modalx">
                    <div className="modalx-content">
                      {/* selectedFile input */}
                      <div
                        style={{ alignSelf: "flex-end" }}
                        onClick={toggleModal}
                      >
                        {" "}
                        close
                      </div>

                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/3/36/McDonald%27s_Golden_Arches.svg"
                        draggable="false"
                        className={"lovin-it2"}
                      />
                      {!IsUploading && (
                        <>
                          {" "}
                          <label htmlFor="fileInput">Choose a file:</label>
                          <input
                            type="file"
                            id="fileInput"
                            accept=".csv"
                            onChange={handleFileChange}
                            style={{ display: "none" }} // Hide the input element
                          />
                          <button
                            className="custButton2"
                            onClick={() =>
                              document.getElementById("fileInput").click()
                            }
                          >
                            Select File
                          </button>
                        </>
                      )}
                      {/* Display selected selectedFile */}
                      {selectedFile && !IsUploading && (
                        <>
                          <p>File: {selectedFile.name}</p>
                          {/* Button to trigger selectedFile upload */}
                          <button
                            className="custButton2"
                            onClick={handleUpload}
                          >
                            Upload File
                          </button>
                        </>
                      )}
                      {/* Upload progress */}
                      {IsUploading && (
                        <div>
                          {/* <h2>Uploading selectedFile...</h2> */}
                          <progress value={progress} max="100"></progress>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {editMode && (
                  <div className="modalx">
                    <div className="modalx-content">
                      {/* selectedFile input */}
                      <label className="editlabel">Edit Store</label>

                      <div className="fields-container">
                        {columns.map((field, fieldindex) => (
                          <div key={fieldindex} className="field-wrapper">
                            <h2 className="modalxh2">{field}</h2>
                            <input
                              type="text"
                              value={EditRow[field]}
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  field,
                                  fieldindex,
                                  EditRow["STORE_ID"]
                                )
                              }
                              className="input-fieldx"
                            />
                          </div>
                        ))}
                      </div>
                      <br />

                      <button
                        className="custButton2"
                        onClick={handleEditSubmit}
                      >
                        Submit
                      </button>

                      <button className="custButton2" onClick={toggleEditMode}>
                        Close
                      </button>
                    </div>
                  </div>
                )}

                <div
                  className="custButton"
                  onClick={() => {
                    setUploadTransactions(false);
                    toggleModal();
                  }}
                >
                  <p className="custButton-text">Upload Stores</p>
                  <FontAwesomeIcon icon={faPlus} className="custButton-icon" />
                </div>
                <div
                  className="custButton"
                  onClick={() => {
                    setUploadTransactions(true);
                    toggleModal();
                  }}
                >
                  <p className="custButton-text">Upload Transactions</p>
                  <FontAwesomeIcon icon={faPlus} className="custButton-icon" />
                </div>

                <button
                  className="custButton"
                  // className="custButton"
                  style={{
                    position: "relative",
                    backgroundColor: "white",
                    border: "none",
                  }}
                  onClick={() => {
                    setisSortFieldDBVisible(!isSortFieldDBVisible);
                  }}
                >
                  <p className="custButton-text">
                    {SortFieldDB ? SortFieldDB : "Select Sort"}
                  </p>
                  <FontAwesomeIcon icon={faSort} className="custButton-icon" />
                  {isSortFieldDBVisible && (
                    <div className="calendar-container">
                      <div className="FilterDropDown-menu">
                        {Storekeys.map((key) => (
                          <span
                            key={key}
                            className="FilterDropDown-item-text"
                            onClick={() => {
                              setSortFieldDB(key);
                            }}
                          >
                            {key}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </button>
                {/* <div className="custButton"
                                   onClick={() => downloaddata()}>
                                    <p className="custButton-text">Download</p>
                                    <FontAwesomeIcon icon={faDownload} className="custButton-icon" />
                                </div> */}
                <div className="custButton" onClick={() => fetchData()}>
                  <p className="custButton-text">Refresh</p>
                  <FontAwesomeIcon
                    icon={faRefresh}
                    className="custButton-icon"
                  />
                </div>
              </div>
            </div>
            <>
              {data != null && !isLoading ? (
                <div>
                  <Table
                    className="mt-3"
                    hover
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                    responsive
                  >
                    <thead>
                      <tr
                        style={{
                          color: "#809FB8",
                          borderBottomWidth: 1,
                          borderBottomColor: "#b4a7d6",
                          fontWeight: "600",
                          textTransform: "uppercase",
                          height: 50,
                          verticalAlign: "middle",
                        }}
                      >
                        {columns.map((column) => (
                          <td
                            key={column}
                            onClick={() => {
                              // alert(column);
                              handleSort(column);
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {column}
                          </td>
                        ))}
                        <td>Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={index}>
                          {columns.map((field, fieldindex) => (
                            <td
                              key={`${fieldindex}`}
                              style={{
                                color: "#06152B",
                                fontWeight: "400",
                                fontSize: "0.81rem",
                              }}
                            >
                              <p>{item[field]}</p>
                            </td>
                          ))}
                          <td
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faEdit}
                              className="custButton-icon"
                              onClick={() => {
                                toggleEditMode(index);
                                setEditRow(item);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    {/* <h1>asdkjnaskds</h1> */}
                  </Table>
                  {/* Math.ceil(totalCount / pageSize) */}
                  <Pagination style={{ float: "right" }}>
                    <Pagination.First onClick={() => handlePageChange(1)} />
                    {getPageItems()}
                    <Pagination.Last
                      onClick={() => handlePageChange(pageCount)}
                    />
                  </Pagination>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignSelf: "center",
                    height: "70vh",
                    backgroundColor: "white",
                  }}
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/3/36/McDonald%27s_Golden_Arches.svg"
                    draggable="false"
                    className="lovin-it"
                  />
                  {/* <Spinner animation="border" /> */}
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
}
