import "./Main.scss";
import McDLogo from "./assets/McDLogo.png";
import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation, useNavigate } from "react-router-dom"; // Import useLocation hook
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import StoresIcon from "./assets/icons/stores.svg";
import ReportsIcon from "./assets/icons/stores.svg";
import SettingsIcon from "./assets/icons/settings.svg";
import HelpIcon from "./assets/icons/info.svg";
import {
  getToken,
  removeToken,
  getRefreshToken,
  removeRefreshToken,
  setRefreshToken,
  isAuthenticated,
} from "../utils/auth";
const SidebarContent = () => {
  const location = useLocation(); // Get current location
  const navigate = useNavigate();

  // Function to determine if a given path matches the current location
  const isActive = (path) => {
    return location.pathname.toLowerCase() === path.toLowerCase();
  };
  const handleLogout = () => {
    removeToken();
    removeRefreshToken();
  };
  return (
    <div
      className={`sidebar2`}
      style={{
        width: "15vw",
        backgroundColor: "white",
      }}
    >
      <Nav className="flex-column ">
        <Navbar.Brand
          href="/Dashboard"
          style={{
            shapeOutside: "20",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={McDLogo}
            alt="McDLogo"
            style={{
              width: "80%",
              height: "auto",
              padding: 30,
              alignSelf: "center",
            }}
          />
        </Navbar.Brand>
        <br />

        {/* Menu */}
        <Nav.Item className="navx-menu-heading">Menu</Nav.Item>

        {/* Add the Stores submenu */}
        <LinkContainer to="/Stores">
          <Nav.Link
            className={`navx-menu-item${isActive("/Stores") ? "-active" : ""}`}
          >
            <img
              src={StoresIcon}
              alt="StoreIcon"
              style={{
                marginRight: 10,
              }}
            />
            Stores
          </Nav.Link>
        </LinkContainer>

        {/* Add the Reports submenu */}
        <LinkContainer to="/Reports">
          <Nav.Link
            className={`navx-menu-item${isActive("/Reports") ? "-active" : ""}`}
          >
            <img
              src={ReportsIcon}
              alt="ReportsIcon"
              style={{
                marginRight: 10,
              }}
            />
            Reports
          </Nav.Link>
        </LinkContainer>

        {/* Others */}
        <Nav.Item className="navx-menu-heading" style={{ marginTop: "40%" }}>
          Others
        </Nav.Item>

        {/* Add the Settings submenu */}
        <LinkContainer to="/Settings">
          <Nav.Link
            className={`navx-menu-item${
              isActive("/Settings") ? "-active" : ""
            }`}
          >
            <img
              src={SettingsIcon}
              alt="SettingsIcon"
              style={{
                marginRight: 10,
              }}
            />
            Settings
          </Nav.Link>
        </LinkContainer>

        {/* Add the Help submenu */}
        <LinkContainer to="/Help">
          <Nav.Link
            className={`navx-menu-item${isActive("/Help") ? "-active" : ""}`}
          >
            <img
              src={HelpIcon}
              alt="HelpIcon"
              style={{
                marginRight: 10,
              }}
            />
            Help
          </Nav.Link>
        </LinkContainer>
        <LinkContainer to="/" onClick={handleLogout}>
          {/* <Nav.Link className={`navx-menu-item${isActive("/Help") ? "-active" : ""}`}>
                        <img
                            src={HelpIcon}
                            alt="HelpIcon"
                            style={{
                                marginRight: 10,
                            }}
                        />
                        Logout
                    </Nav.Link> */}
          {!isAuthenticated() ? (
            <Nav.Link
              onClick={() => {
                navigate("/login");
              }}
            >
              Sign In
            </Nav.Link>
          ) : (
            <Nav.Link
              onClick={() => {
                console.log("logout");
                removeToken();
                removeRefreshToken();
                navigate("/login");
              }}
            >
              Logout
            </Nav.Link>
          )}
        </LinkContainer>
      </Nav>
    </div>
  );
};

export default SidebarContent;
