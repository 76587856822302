import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import "react-calendar/dist/Calendar.css";
import { components } from "react-select";
import { Button, Pagination, Table } from "react-bootstrap";
import search_icon from "../assets/search_icon.svg";
import download_icon from "../assets/download_icon.svg";
import refresh_icon from "../assets/refresh_icon.svg";
import Calendar from "react-calendar";
import { default as ReactSelect } from "react-select";
import "./Main.scss";
import McDLogo from "./assets/McDLogo.png";
import axios from "axios";
import * as XLSX from "xlsx";
import { GDSRecords } from "../ENDE";
import Swal from "sweetalert2";

import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faPlus,
  faDownload,
  faSort,
  faEdit,
  faRefresh,
  faEye,
  faRupeeSign,
  faIndianRupeeSign,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import SidebarContent from "./Sidebar";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import CryptoJS from "crypto-js";
import { useLocation, useNavigate } from "react-router-dom";
// const apiEN = process.env.REACT_APP_GES1;
const apiDE = process.env.REACT_APP_GES3;
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler); // Clean up the timeout when value or delay changes
    };
  }, [value, delay]);

  return debouncedValue;
};
export default function ConflictRecords() {
  const { state } = useLocation();
  const [page, setPage] = useState(1);
  const initialTColumns = [
    // Add more columns as needed
  ];
  // SELECT COLUMNS CODE START
  const [T_columns, setTColumns] = useState(initialTColumns);
  const [selectedTColumns, setSelectedTColumns] = useState([]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedTColumns = Array.from(T_columns);
    const [removed] = reorderedTColumns.splice(result.source.index, 1);
    reorderedTColumns.splice(result.destination.index, 0, removed);

    setTColumns(reorderedTColumns);
  };

  const handleSelectTColumn = (column) => {
    setSelectedTColumns((prevSelected) =>
      prevSelected.includes(column)
        ? prevSelected.filter((col) => col !== column)
        : [...prevSelected, column]
    );
  };
  const [SelectedRecord, setSelectedRecord] = useState("Conflict");
  const [pageSize, setPageSize] = useState(10);
  const [shouldFetchData, setShouldFetchData] = useState(true);
  // State to manage start and end date/time
  const formatDateTime = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  // Get today's start and end times
  const getTodayStartEndTimes = () => {
    const now = new Date();
    const startOfDay = new Date(now.setHours(0, 0, 0, 0)); // Start of today
    const endOfDay = new Date(now.setHours(23, 59, 59, 999)); // End of today

    return {
      start: formatDateTime(startOfDay),
      end: formatDateTime(endOfDay),
    };
  };
  const { start, end } = getTodayStartEndTimes();
  const [startDateTime, setStartDateTime] = useState(start);
  const [endDateTime, setEndDateTime] = useState(end);

  // Handlers for the inputs
  const handleStartDateTimeChange = (event) => {
    setStartDateTime(event.target.value);
  };

  const handleEndDateTimeChange = (event) => {
    setEndDateTime(event.target.value);
  };
  const handleFocus = (event) => {
    event.target.showPicker(); // Show date picker when input is focused
  };
  const [selectedSearchFilter, setSelectedSearchFilter] = useState(null);
  const [isDateConstVisible, setIsDateConstVisible] = useState(false);
  const [isSortFieldDBVisible, setisSortFieldDBVisible] = useState(false);
  const [SortFieldDB, setSortFieldDB] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [DateConst, setDateConst] = useState(new Date());
  const [totalCount, setTotalCount] = useState(0);
  const [selectedFile, setselectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [columns, setcolumns] = useState(null);
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [IsUploading, setIsUploading] = useState(false);
  const [keys, setkeys] = useState([]);

  const [selectedAndOrderedColumns, setSelectedAndOrderedColumns] = useState(
    []
  );
  const handleFileChange = (e) => {
    try {
      console.log(e.target.files[0]);
      setselectedFile(e.target.files[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    if (debouncedSearchValue) {
      // Call your search function or API request here
      console.log("Searching for:", debouncedSearchValue);
      fetchData(debouncedSearchValue);
      // Simulating a search API call
      //   const mockResults = ["Result 1", "Result 2", "Result 3"]; // Replace with actual API
      //   setResults(mockResults);
    }
    // else {
    //   setResults([]);
    // }
  }, [debouncedSearchValue]);
  const toggleModal = (close) => {
    setModalOpen(!modalOpen);
    setProgress(0); // Reset progress when modal opens
    if (close === true) {
      setselectedFile(null);
      setModalOpen(false);
    }
  };
  const toggleModal2 = (close) => {
    setModalOpen2(!modalOpen2);
    if (close === true) {
      setModalOpen2(false);
    }
  };
  const [sortOrder, setSortOrder] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [verifyText, setVerifyText] = useState([]);

  const handleRowClick = (itemId) => {
    if (selectedRows.includes(itemId)) {
      setSelectedRows(selectedRows.filter((id) => id !== itemId));
    } else {
      setSelectedRows([...selectedRows, itemId]);
    }
  };
  const [data, setdata] = useState(null);
  const [editMode, seteditMode] = useState(false);
  const [editedValues, setEditedValues] = useState(
    Array(data?.length).fill({})
  );
  const [EditRow, setEditRow] = useState([]);
  const toggleEditMode = () => {
    seteditMode(!editMode);
  };

  const handleInputChange = (e, field, index, id) => {
    const { value } = e.target;
    console.log(field);
    // Validation: Check if the field is either "pincode" or "store_id" and if the value is a number
    if (
      (field === "PIN_CODE" || field === "STORE_ID") &&
      isNaN(Number(value))
    ) {
      // If the value is not a number, you can handle the error here
      console.error(`Invalid input for ${field}: ${value}`);
      // Don't update the state if the input is invalid
      return;
    }
    setEditRow((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const [ccList, setccList] = useState([]);

  const handlePageChange = (value) => {
    setPage(value);
  };
  const pageCount = Math.ceil(totalCount / pageSize);
  const onDateConstChange = (value) => {
    setDateConst(value);
    setIsDateConstVisible(!isDateConstVisible);
  };
  function getDateLabel(date) {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const inputDate = new Date(date);
    inputDate.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, and milliseconds

    if (inputDate.toDateString() === today.toDateString()) {
      return "Today";
    } else if (inputDate.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else {
      return inputDate.toISOString().substring(0, 10);
    }
  }

  function TableStatusOLD({ text }) {
    const textx = text?.toLowerCase();
    // console.log(textx);
    // console.log(textx === 'failed');
    const color =
      textx === "failed" || textx === "cancelled"
        ? "#EB5757"
        : textx === "pending"
        ? "#F2994A"
        : "#12A474";
    const bgcolor =
      textx === "failed" || textx === "cancelled"
        ? "#FFDBDB"
        : textx === "pending"
        ? "#FFF3DB"
        : "#BBF3E0";
  }
  function TableStatus({ text }) {
    const textx = text?.toLowerCase();
    // console.log(textx);
    // console.log(textx === 'failed');
    const color =
      textx === "failed"
        ? "#EB5757"
        : textx === "cancelled"
        ? "#F2994A"
        : "#12A474";
    const bgcolor =
      textx === "failed"
        ? "#FFDBDB"
        : textx === "cancelled"
        ? "#FFF3DB"
        : "#BBF3E0";
    return (
      <span
        style={{
          backgroundColor: bgcolor,
          color: color,
          fontSize: 12,
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
          borderRadius: 8,
          textTransform: "capitalize",
        }}
      >
        {text}
      </span>
    );
  }
  const fetchData = async (searchValuex) => {
    try {
      setIsLoading(true);
      console.log({
        sortField: SortFieldDB,
        searchValue: searchValue,
        searchFilter: selectedSearchFilter,
      });
      await axios({
        url: process.env.REACT_APP_API_LINK + `/rfapi/getConflictReportData`,
        method: "POST",
        responseType: "json",
        // withCredentials: true,

        data: {
          searchValue: searchValuex ? searchValuex : searchValue,
          searchFilter: selectedSearchFilter,
          sortField: SortFieldDB,
          SelectedRecord: SelectedRecord,
          page: page,
          sortOrder: !sortOrder ? "asc" : sortOrder,
          STORE_ID: state.STORE_ID,
          startDateTime: startDateTime,
          endDateTime: endDateTime,
        },
      }).then((response) => {
        if (response) {
          var datax = response.data.data;
          console.log(datax);
          if (datax) {
            // datax = datax.AllRecords;
            console.log(datax);
            console.log(typeof datax);
            setdata(datax);
            setTotalCount(response.data.totalCount);
            if (datax.length > 1) {
              setTColumns(Object.keys(datax[0]));
              setcolumns(Object.keys(datax[0]));
              setkeys(Object.keys(datax[0]));
              const createCCList = (data) => {
                return Object.keys(data).map((key) => ({
                  value: key,
                  label: key
                    .replace(/_/g, " ")
                    .toLowerCase()
                    .replace(/^\w/, (c) => c.toUpperCase()),
                }));
              };
              setccList(createCCList(datax[0]));
              const storedColumns = JSON.parse(
                localStorage.getItem("filteredColumns")
              );
              setSelectedTColumns(
                storedColumns ? storedColumns : Object.keys(datax[0])
              );
              setSelectedAndOrderedColumns(storedColumns);
            }
          }
        }
      });
    } catch (err) {
      console.log("error fetching ");
      alert("error fetching data");
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  const downloaddata = async () => {
    try {
      const response = await axios({
        url: process.env.REACT_APP_API_LINK + `/rfapi/getReportData`,
        method: "POST",
        responseType: "json",
        // withCredentials: true,

        data: {
          sortField: SortFieldDB,
          searchValue: searchValue,
          searchFilter: selectedSearchFilter,
          page: page,
          download: true,
        },
        responseType: "blob", // Set the response type to blob to handle binary data
      });

      // Create a blob object from the response data
      const blob = new Blob([response.data], { type: "text/csv" });

      // Create a download link
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "data.csv";

      // Append the link to the body and click it to trigger the download
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Cleanup
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (startDateTime && endDateTime) {
      fetchData();
    }
  }, [startDateTime, endDateTime]);
  // useEffect(() => {
  //         fetchData(SortFieldDB, searchValue, selectedSearchFilter,page,sortOrder);
  // }, [page]);
  useEffect(() => {
    // if (SortFieldDB) {
    fetchData();
    // }
  }, [SortFieldDB, selectedSearchFilter, sortOrder, page, SelectedRecord]);

  const handleSort = (field) => {
    console.log(field);
    console.log(SortFieldDB);
    console.log(sortOrder);
    if (field === SortFieldDB) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortFieldDB(field);
      setSortOrder("asc");
    }
    setPage(1);
  };
  const pageNumbers = [];
  for (let i = 1; i <= pageCount; i++) {
    pageNumbers.push(i);
  }

  const getPageItems = () => {
    const items = [];

    // Add Prev button
    items.push(
      <Pagination.Prev
        key="prev"
        onClick={() => {
          if (page > 1) {
            handlePageChange(page - 1);
          }
        }}
        disabled={page <= 1}
      />
    );

    // Add page numbers
    for (
      let i = Math.max(1, page - 4);
      i <= Math.min(pageCount, page + 5);
      i++
    ) {
      items.push(
        <Pagination.Item
          key={i}
          active={page === i}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    // Add Next button
    items.push(
      <Pagination.Next
        key="next"
        onClick={() => {
          if (page < pageCount) {
            handlePageChange(page + 1);
          }
        }}
        disabled={page >= pageCount}
      />
    );

    return items;
  };

  const NavV = () => {
    return (
      <p
        className="align-self-end"
        style={{
          padding: "2%",
          display: "flex",
          backgroundColor: "white",
          margin: 0,
          marginTop: "0rem",
          flexDirection: "row",
          alignItems: "center",
          borderBottom: "0.1px solid gray",
          // , marginBottom: 20
        }}
      >
        <p
          className="align-self-end"
          style={{
            margin: 0,
            marginTop: "0rem",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/Stores");
          }}
        >
          {"Home >"}
        </p>
        <p
          className="align-self-end"
          style={{
            margin: 0,
            marginTop: "0rem",
            marginLeft: "0.51rem",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/Reports");
          }}
        >
          {"Reports >"}
        </p>
        <p
          className="align-self-end"
          style={{
            margin: 0,
            marginTop: "0rem",
            marginLeft: "0.51rem",
            fontWeight: "700",
            cursor: "pointer",
          }}
        >
          {state.STORE_NAME + "#" + state.STORE_ID}
        </p>
      </p>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        width: "100%",
        backgroundColor: "#f5f5f5",
      }}
    >
      <SidebarContent />
      <div
        style={{
          padding: "0%",
          margin: "0%",
          flex: 1,
        }}
      >
        <NavV />

        <div
          style={{
            padding: "1.52%",
            // borderRadius: "20px",
            backgroundColor: "white",
          }}
        >
          {/* NAVBAR */}
          <div>
            <div className="d-flex flex-wrap" style={{ marginBottom: 10 }}>
              <div
                className={"disabledRecord"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/AllRecords", { state: state });
                }}
              >
                <p style={{ margin: 0 }}>All Records</p>
              </div>
              <div
                className={"activeRecord"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/ConflictRecords", { state: state });
                }}
              >
                <p style={{ margin: 0 }}>Conflict Records</p>
              </div>
            </div>
            <div
              className="d-flex flex-wrap"
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* SEARCH BAR */}
              <div className="customx-input-group" style={{ maxWidth: 800 }}>
                <img
                  src={search_icon}
                  alt=""
                  width={40}
                  style={{
                    padding: 10,
                  }}
                  id="basic-addon1"
                />
                <input
                  type="text"
                  className="customx-form-control"
                  style={{
                    minWidth: 400,
                  }}
                  value={searchValue}
                  onChange={(event) => setSearchValue(event.target.value)}
                  placeholder="Search here"
                />
                <ReactSelect
                  className="customx-form-control border-light"
                  styles={{
                    control: (baseStyle, state) => ({
                      ...baseStyle,
                      borderColor: "white",
                      "&:hover": {
                        borderColor: "white",
                      },
                      boxShadow: 0,
                    }),
                  }}
                  placeholder={"Search Field"}
                  options={ccList}
                  hideSelectedOptions={false}
                  isClearable={true}
                  onChange={setSelectedSearchFilter}
                  value={selectedSearchFilter}
                />
              </div>

              {/* lassi */}

              <div
                style={{
                  marginRight: "20px",
                }}
              >
                <label>Start Date and Time:</label>
                <input
                  className="custButton"
                  type="datetime-local"
                  value={startDateTime}
                  onChange={handleStartDateTimeChange}
                  style={{ width: "100%", maxWidth: "100%" }}
                  onFocus={handleFocus} // Trigger date picker on focus
                />
              </div>

              <div
                style={{
                  marginRight: "20px",
                }}
              >
                <label>End Date and Time:</label>
                <input
                  className="custButton"
                  type="datetime-local"
                  value={endDateTime}
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                  }}
                  onChange={handleEndDateTimeChange}
                  onFocus={handleFocus} // Trigger date picker on focus
                />
              </div>

              <div
                className="custButton"
                style={{
                  display: SelectedRecord !== "Conflict" && "none",
                }}
                onClick={() => toggleModal2()}
              >
                <p className="custButton-text">Refund Selected</p>
                <FontAwesomeIcon
                  icon={faIndianRupeeSign}
                  className="custButton-icon"
                />
              </div>
              <div className="custButton" onClick={() => toggleModal()}>
                <p className="custButton-text">Select Columns</p>
                <FontAwesomeIcon
                  // icon="fa-regular fa-square-check"
                  icon={faCheck}
                  className="custButton-icon"
                />
              </div>
              <button
                className="custButton"
                // className="custButton"
                style={{
                  position: "relative",
                  backgroundColor: "white",
                  border: "none",
                }}
                onClick={() => {
                  setisSortFieldDBVisible(!isSortFieldDBVisible);
                }}
              >
                <p className="custButton-text">
                  {SortFieldDB ? SortFieldDB : "Select Sort"}
                </p>
                <FontAwesomeIcon icon={faSort} className="custButton-icon" />
                {isSortFieldDBVisible && (
                  <div className="calendar-container">
                    <div className="FilterDropDown-menu">
                      {keys &&
                        keys.map((key) => (
                          <span
                            key={key}
                            className="FilterDropDown-item-text"
                            onClick={() => {
                              setPage(1);
                              setSortFieldDB(key);
                            }}
                          >
                            {key}
                          </span>
                        ))}
                    </div>
                  </div>
                )}
              </button>

              <div className="custButton" onClick={() => fetchData()}>
                <p className="custButton-text">Refresh</p>
                <FontAwesomeIcon icon={faRefresh} className="custButton-icon" />
              </div>
            </div>
          </div>
          <>
            {data?.length > 1 && data != null && !isLoading ? (
              <div
                style={{
                  textAlign: "center",
                  alignItems: "center",
                  width: "80vw",
                }}
              >
                <Table
                  className="mt-3"
                  hover
                  style={{
                    textAlign: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                  responsive
                >
                  <thead>
                    <tr
                      style={{
                        color: "#809FB8",
                        borderBottomWidth: 1,
                        borderBottomColor: "#b4a7d6",
                        fontWeight: "600",
                        textTransform: "uppercase",
                        // height: 50,
                        verticalAlign: "middle",
                      }}
                    >
                      {SelectedRecord === "Conflict" && <td> Action</td>}
                      {selectedAndOrderedColumns.map((column) => (
                        <td
                          key={column}
                          onClick={() => {
                            // alert(column);
                            handleSort(column);
                          }}
                          style={{
                            cursor: "pointer",
                            width: "100%",
                          }}
                        >
                          {column}
                        </td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          if (item.Refundable.toLowerCase() === "yes") {
                            handleRowClick(item.TransactionID);
                          } else {
                            console.log("ignore");
                          }
                        }}
                        style={{
                          color: "#06152B",
                          fontWeight: "400",
                          width: "100%",
                          fontSize: "0.81rem",
                          cursor: "pointer",
                        }}
                      >
                        {SelectedRecord === "Conflict" && (
                          <td>
                            <input
                              type="checkbox"
                              style={{
                                display:
                                  item.Refundable.toLowerCase() === "yes"
                                    ? "block"
                                    : "none",
                              }}
                              checked={selectedRows.includes(
                                item.TransactionID
                              )}
                            />
                          </td>
                        )}
                        {selectedAndOrderedColumns.map((field, fieldindex) => (
                          <td key={`${fieldindex}`} style={{}}>
                            {field === "TxnStatus" ? (
                              <TableStatus text={item[field]} />
                            ) : (
                              <p
                                style={{
                                  width: "100%",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {item[field]}
                              </p>
                            )}
                          </td>
                        ))}
                        {/* <td
                        style={{
                            cursor:'pointer'
                        }}
                    >
                       
                                                       <FontAwesomeIcon icon={faEye} className="custButton-icon" onClick={() =>{ 
                                                        
                                                        
                                                        navigate("/AllRecords", {
                                                            state: {
                                                              Store_ID: item['Store_ID']
                                                            },
                                                          });
                            }} />
                          
                    </td> */}
                      </tr>
                    ))}
                  </tbody>
                  {/* <h1>asdkjnaskds</h1> */}
                </Table>
                {/* Math.ceil(totalCount / pageSize) */}
                <Pagination style={{ float: "right" }}>
                  <Pagination.First onClick={() => handlePageChange(1)} />
                  {getPageItems()}
                  <Pagination.Last
                    onClick={() => handlePageChange(pageCount)}
                  />
                </Pagination>

                {modalOpen && (
                  <div className="modalx">
                    <div
                      className="modalx-content"
                      style={{
                        maxHeight: "80vh",
                        overflowY: "auto",
                        padding: "16px",
                      }}
                    >
                      {/* Close button */}
                      <div
                        style={{
                          alignSelf: "flex-end",
                          cursor: "pointer",
                          padding: "8px 16px",
                          borderRadius: "4px",
                          backgroundColor: "#edf2f7",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                        onClick={toggleModal}
                      >
                        Close
                      </div>

                      {/* Available Columns */}
                      <div>
                        <h3
                          style={{
                            marginBottom: "16px",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          Select & Reorder Columns
                        </h3>
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <Droppable droppableId="T_columns">
                            {(provided) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                  padding: "8px",
                                  backgroundColor: "#f7fafc",
                                  borderRadius: "4px",
                                  maxHeight: "400px",
                                  overflowY: "auto",
                                }}
                              >
                                {T_columns.map((column, index) => (
                                  <Draggable
                                    key={column}
                                    draggableId={column}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        onClick={() =>
                                          handleSelectTColumn(column)
                                        } // Toggle checkbox on row click
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          ...provided.draggableProps.style,
                                          display: "flex",
                                          alignItems: "center",
                                          padding: "8px",
                                          marginBottom: "8px",
                                          backgroundColor:
                                            selectedTColumns.includes(column)
                                              ? "#e2e8f0"
                                              : "#f7fafc",
                                          borderRadius: "4px",
                                          border: "1px solid #cbd5e0",
                                          cursor: "pointer",
                                          fontSize: "14px",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={selectedTColumns.includes(
                                            column
                                          )}
                                          onChange={() =>
                                            handleSelectTColumn(column)
                                          }
                                          style={{ marginRight: "8px" }}
                                        />
                                        {column}
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>

                      <div
                        style={{
                          marginTop: "1.2rem",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{ fontSize: "14px" }}
                          onClick={() => {
                            setSelectedTColumns(columns);
                          }}
                        >
                          {" "}
                          Select All{" "}
                        </p>
                        <p
                          style={{ fontSize: "14px" }}
                          onClick={() => {
                            setSelectedTColumns([]);
                          }}
                        >
                          Select None
                        </p>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          alignSelf: "center",
                          cursor: "pointer",
                          padding: "8px 16px",
                          borderRadius: "4px",
                          backgroundColor: "#edf2f7",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          // Log selectedRows to see its content
                          console.log("Selected Rows:", selectedTColumns);

                          // Assuming you want to filter T_columns based on some condition,
                          // here’s how you could log the filtered result:

                          const filteredColumns = T_columns.filter((column) => {
                            // Your filtering condition here, for example:
                            return selectedTColumns.includes(column);
                          });

                          console.log("Filtered Columns:", filteredColumns);
                          localStorage.setItem(
                            "filteredColumns",
                            JSON.stringify(filteredColumns)
                          );
                          setSelectedAndOrderedColumns(filteredColumns);
                          fetchData();
                          setModalOpen(false);
                        }}
                      >
                        Submit
                      </div>
                    </div>
                  </div>
                )}

                {modalOpen2 && (
                  <div className="modalx">
                    <div
                      className="modalx-content2"
                      style={{
                        maxHeight: "100%",
                        overflowY: "auto",
                        padding: "16px",
                      }}
                    >
                      {/* Close button */}
                      <div
                        style={{
                          alignSelf: "flex-end",
                          cursor: "pointer",
                          padding: "8px 16px",
                          borderRadius: "4px",
                          backgroundColor: "#edf2f7",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                        onClick={toggleModal2}
                      >
                        Close
                      </div>

                      {/* Available Columns */}
                      <div>
                        <h3
                          style={{
                            marginBottom: "16px",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          Confirm Refund
                        </h3>
                      </div>

                      {/* Display Filtered Rows */}
                      <div>
                        {data.filter((row) =>
                          selectedRows.includes(row.TransactionID)
                        ).length > 0 ? (
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                {Object.keys(
                                  data.filter((row) =>
                                    selectedRows.includes(row.TransactionID)
                                  )[0]
                                ).map((key) => (
                                  <th key={key}>{key}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {data
                                .filter((row) =>
                                  selectedRows.includes(row.TransactionID)
                                )
                                .map((row) => (
                                  <tr key={row.TransactionID}>
                                    {Object.keys(row).map((key) => (
                                      <td key={key}>{row[key]}</td>
                                    ))}
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        ) : (
                          <div>No rows selected.</div>
                        )}
                      </div>

                      {/* Verification Input */}
                      <div
                        className="customx-input-group"
                        style={{ maxWidth: "100%" }}
                      >
                        <input
                          type="text"
                          className="customx-form-control border-light"
                          style={{
                            minWidth: 400,
                          }}
                          value={verifyText}
                          onChange={(event) =>
                            setVerifyText(event.target.value)
                          }
                          placeholder="Type confirm to verify"
                        />
                        <br />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          alignSelf: "center",
                          cursor: "pointer",
                          padding: "8px 16px",
                          borderRadius: "4px",
                          backgroundColor: "#edf2f7",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          // Log selectedRows to see its content
                          console.log("Selected Rows:", selectedTColumns);
                          alert("success");
                        }}
                      >
                        Proceed to Refund
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "center",
                  height: "70vh",
                  backgroundColor: "white",
                }}
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/3/36/McDonald%27s_Golden_Arches.svg"
                  draggable="false"
                  className="lovin-it"
                />
                {/* <Spinner animation="border" /> */}
              </div>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignSelf: "center",
                    alignItems: "center",
                    height: "70vh",
                    backgroundColor: "white",
                  }}
                >
                  <h3>No data found</h3>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
}
