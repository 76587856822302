import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from './utils/auth';

const NotLoggedInPage = () => {
  const navigate = useNavigate();

  const redirectToLogin = () => {
    // Redirect to the login page
    navigate('/login'); // Update the route according to your setup
  };


  const pageStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: 'white'
  };

  const cardStyle = {
    textAlign: 'center',
    padding: '5%',
    // border: '1px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#e21923',
  };

  const headingStyle = {
    color: 'white',
    marginBottom: '15px',
    fontSize: '2rem'
  };

  const paragraphStyle = {
    color: 'white',
    marginBottom: '15px',
  };

  const buttonStyle = {
    backgroundColor: '#ffcc00',
    color: '#e21923',
    fontWeight: "900",

    padding: '10px 20px',
    borderRadius: '4px',
    cursor: 'pointer',
    border: 'none',
    width: '100%',
    marginTop: '15px',
  };

  return (
    <div style={pageStyle}>
      <div style={cardStyle}>
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/3/36/McDonald%27s_Golden_Arches.svg"
          draggable="false"
          className={"lovin-it2"}
        />
        <h1 style={headingStyle}>You are not logged in</h1>
        <p style={paragraphStyle}>Please log in to access this page.</p>
        <button style={buttonStyle} onClick={redirectToLogin}>
          Go to Login
        </button>
      </div>
    </div>
  );
};

export default NotLoggedInPage;
